import { Box, Button, TextField } from '@mui/material'
import React, { useState } from 'react'
import { toggleLoader } from '../../Store/loader/loaderReducer';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { paaQuestionsAction } from '../../Store/PaaQuestions/paaQuestionsAction';

const NewPost = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const org_info = useSelector((state) => state?.org_info?.value);
  const org_id = org_info?.default_organizations?.Id;

  const [keywords, setkeywords] = useState("");


  const handleValidate = () => {
    validateInput(keywords)
    // console.log('isButtonDisabled,',isButtonDisabled);

  }

  const handleSubmit = () => {

    dispatch(toggleLoader(true));
    let data = {
      org_id: org_id,
      q: keywords
    };

    axios
      .get(process.env.REACT_APP_PAAQUESTIONS, {
        params: data,
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
      .then((res) => {
        dispatch(paaQuestionsAction(res?.data));
        navigate(`/c${org_id}/related-questions`, { state: { type: 'newPost' } })
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        if(err.response.status===400){
            toast.error(err.response.data.error.details);
        }
        else toast.error(err.message);
        dispatch(toggleLoader(false));
      });

  }

  const validateInput = (value) => {
    const regex = /[a-zA-Z]{5}/;
    regex.test(value) ? handleSubmit() : toast.error('Please enter at least 5 characters.');;
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh', textAlign: 'center' }}>
      <Box>
        <Box sx={{ padding: '5px' }}>
          <img alt="user_img" src={process.env.REACT_APP_IMAGE_URL + "NewPost/search-pc.svg"} />
        </Box>
        <Box sx={{ padding: '5px' }}>Enter keyword for blog ideas from popular questions</Box>
        <TextField sx={{ padding: '10px' }}
          onChange={(e) => setkeywords(e.target.value)}
          id="outlined-multiline-static"
          multiline
          minRows={4}
          maxRows={10}
          fullWidth
        />
        <Box sx={{ textAlign: 'end', padding: '10px' }}> <Button onClick={() => { handleValidate() }} variant="contained">Submit</Button></Box>
      </Box>
    </Box>
  )
}

export default NewPost