import { Box, Button, FormControl, MenuItem, OutlinedInput, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { toggleLoader } from '../../../Store/loader/loaderReducer'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'

const AiPrompt = () => {
    const [formValue, setFormValue] = useState({ type: 'ai_blog', prompt: ``, updatedPrompt: `` })
    const dispatch = useDispatch();
    const org_info = useSelector((state) => state?.org_info?.value);
    const org_Value = org_info?.default_organizations?.Id;
    const [org_id, setOrg_Id] = useState()

    let data = { type: formValue.type, prompt: formValue.updatedPrompt, org_id: org_id ? org_id : 0  }



    useEffect(() => {
        _getPrompt()
        setOrg_Id(org_Value && org_Value)
    }, [formValue.type])

    const _getPrompt = () => {
        let data = { type: formValue.type, org_id: org_id ? org_id : 0 }
        axios.get(process.env.REACT_APP_AIPROMPT, { params: data, withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((res) => {
                setFormValue({ ...formValue, type: res.data.data.Type, updatedPrompt: res.data.data.Prompt })
                dispatch(toggleLoader(false))
            })

            .catch((err) => {
                toast.error('No valid prompt found for this organization.');
                dispatch(toggleLoader(false))
            })
    }


    const handleSubmit = () => {
        axios.post(process.env.REACT_APP_AIPROMPT, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((res) => {
                dispatch(toggleLoader(false))
                toast.success('Success');
                _getPrompt()
            })

            .catch((err) => {
                toast.error(err.message);
                dispatch(toggleLoader(false))
            })
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            _getPrompt()
        }
      };
    return (
        <Box sx={{ padding: "0px 40px", width: '100%', display: 'flex', gap: '20px', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Org Id:</Box>
                <FormControl size="small" sx={{ width: "100%" }} >
                    <OutlinedInput
                        id="outlined-adornment-weight"
                        style={{ height: 35, width: '250px' }}
                        onChange={e=>setOrg_Id(e.target.value)}
                        onKeyPress={handleKeyPress}
                    />
                </FormControl>
            </Box>
            <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Type:</Box>
                <FormControl size="small" sx={{ width: "100%" }} >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"

                        value={formValue.type}
                        onChange={(e) => setFormValue({ ...formValue, type: e.target.value })}
                        style={{ height: 35, width: '250px' }}
                    >
                        <MenuItem value={'ai_blog'}>ai_blog</MenuItem>
                        <MenuItem value={'crawl_prompt'}>crawl_prompt</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            {/* <Box sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                    <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Prompt:</Box>
                    <FormControl size="small" sx={{ width: "100%" }} >
                        <TextField style={{ width: '100%' }}
                            minRows={5}
                            maxRows={5}
                            multiline
                            value={formValue.prompt}
                            placeholder='The prompt should have this title placeholder: {{TITLE_PROMPT_PLACEHOLDER}}'
                            onChange={(e) => setFormValue({ ...formValue, prompt: e.target.value })}
                        />
                    </FormControl>
                </Box>
            </Box> */}
            {/* {formValue.updatedPrompt.length &&  */}
            <Box sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                    <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Live Prompt :</Box>
                    <FormControl size="small" sx={{ width: "100%" }} >

                        <TextField style={{
                            width: '100%'
                        }}
                            minRows={15}
                            maxRows={17}
                            multiline
                            value={formValue.updatedPrompt}
                        // placeholder='The prompt should have this title placeholder: {{TITLE_PROMPT_PLACEHOLDER}}'
                        onChange={(e) => setFormValue({ ...formValue, updatedPrompt: e.target.value })}
                        // disabled
                        />
                    </FormControl>
                </Box>
            </Box>
            {/* } */}
            <Box sx={{ paddingTop: '20px', width: '100%', textAlign: 'end' }}>
                <Button variant="contained" onClick={handleSubmit}>Submit</Button>
            </Box>

        </Box>
    )
}

export default AiPrompt