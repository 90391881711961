import { paaQuestionsDetail } from './paaQuestionsReducer';

export const paaQuestionsAction = (query) => async (dispatch) => {
    try {
        dispatch(paaQuestionsDetail(query));
    } catch (err) {
        const error = {
            code: 0,
            message: typeof err == 'object' ? err.message : err,
        };
        throw error;
    }
};