import { combineReducers } from 'redux';
import loader from './loader/loaderReducer';
import org_info from './OrgInfo/orgInfoReducer';
import post_info from './PostInfo/postInfo_reducer';
import admin_info from './AdminInfo/adminInfoReducer';
import paa_question from './PaaQuestions/paaQuestionsReducer';

const rootReducer = combineReducers({ loader, org_info, post_info,admin_info, paa_question});

export default rootReducer;
