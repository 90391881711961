import axios from "axios";

export const getChannelConnect = (data, resp, error) => {
    let options = { org_id: data.org_id, channel_type: data.channel_type };
    axios.get(process.env.REACT_APP_ZOHO_CRM, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: options })
        .then((res) => {
            resp(res);
        })
        .catch((err) => {
            error(err);
        });
};

export const getWixConnect = (data, resp, error) => {
    let options = { org_id: data.org_id, channel_type: data.channel_type };
    axios.get(process.env.REACT_APP_WIX_CONNECT, { 
        withCredentials: process.env.REACT_APP_CREDENTIALS, 
        // params: options
     })
        .then((res) => {
            resp(res);
        })
        .catch((err) => {
            error(err);
        });
};