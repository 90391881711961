import { Box, Button, Checkbox, FormControlLabel, FormGroup, InputAdornment, TextField, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import styles from "../CustomTable/CustomTable.module.css";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation, useNavigate } from 'react-router-dom';

const PostTableView = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const type = location?.state?.type



  const paa_question = useSelector((state) => state?.paa_question);
  const question = paa_question?.value?.data

  const question_lowercase = question.map(obj =>
    Object.keys(obj).reduce((acc, key) => {
      acc[key.toLowerCase()] = obj[key];
      return acc;
    }, {})
  );


  const org_info = useSelector((state) => state?.org_info?.value);
  const org_id = org_info?.default_organizations?.Id;

  const [data, setData] = useState(question_lowercase);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');


  const filteredData = question_lowercase.filter(item =>
    item.question.toLowerCase().includes(searchTerm.toLowerCase())
  );


  const displayData = searchTerm ? filteredData : data;


  const handleCheckboxChange = (index) => {
    if (selectedIndexes.includes(index)) {
      setSelectedIndexes(selectedIndexes.filter(i => i !== index));
    } else {
      setSelectedIndexes([...selectedIndexes, index]);
    }
  };

  // Handle selecting/deselecting all checkboxes
  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      const allIndexes = displayData.map((_, index) => index);
      setSelectedIndexes(allIndexes);
    } else {
      setSelectedIndexes([]);
    }
  };


  console.log('data', data[0].crawl_activity_log_id);

  useEffect(() => {
    if (type === undefined) {
      if (data[0].crawl_activity_log_id) {
        navigate(`/c${org_id}/crawl`)
      }
      else navigate(`/c${org_id}/create-new-post`)
    }

  }, []);

  useEffect(() => {
    const selectedValue = selectedIndexes.map(index => displayData[index]);
    setSelectedValue(selectedValue);
  }, [selectedIndexes]);




  const isAllSelected = displayData?.length === selectedIndexes?.length;

  const handleNavigate = (nav) => {
    if (nav === "next") {
      navigate(`/c${org_id}/post-settings`, { state: { selectedValue } });
    }
    else {
      if (type === 'crawl') {
        navigate(`/c${org_id}/crawl`);
      }
      else navigate(`/c${org_id}/create-new-post`);
    }

  };
  return (
    question && <Box sx={{ padding: "2rem" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", padding: '10px' }}>
        <Button onClick={() => handleNavigate("back")} variant="contained">Back</Button>
        <Button disabled={!selectedValue.length} onClick={() => handleNavigate('next')} variant="contained">Next</Button>
      </Box>
      <Box className={styles.table_border_top}>
        <Box className={styles.table_border}>
          <Box>
            <FormGroup>
              <FormControlLabel
                className={styles.table_Select_box}
                control={
                  <Checkbox
                    checked={isAllSelected}
                    onChange={handleSelectAllChange}
                  />
                }
                label={
                  selectedIndexes?.length
                    ? `${selectedIndexes?.length} Selected`
                    : "Select all"
                }
                disabled={!displayData?.length}
              />
            </FormGroup>
          </Box>
          <Box className={styles.table_filter}>

            <Box>
              <TextField
                className="input_area_mui"
                // ref={inputRef}
                sx={{
                  borderRadius: '30px',
                  height: '2rem',
                }}
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                id="myInput"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

          </Box>
        </Box>

        <Box className={styles.table_body_data}>
          {displayData?.map((item, idx) => (
            <Box
              className={`${styles.table_user_title} `}
              // ${isSelected(item) && styles.table_user_left_border}
              key={idx}
            >
              <Box className={styles.table_user_title_border} sx={{ padding: "10px 30px" }}>
                <Box className={styles.table_user_details}>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedIndexes.includes(idx)}
                          onChange={() => handleCheckboxChange(idx)}
                        />
                      }
                    />
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <Box className={styles.table_title_name}  >
                      {item.question}
                    </Box>
                  </Box>
                </Box>
                <Tooltip
                  title={item.url}
                  componentsProps={{ tooltip: { sx: { bgcolor: "#3a3a3a" } } }}
                  placement="top"
                >
                  <Box className={styles.table_btn_div} style={{ width: '35%', whiteSpace: 'nowrap' }}>
                    <Box className={styles.table_user_more_icon}
                      component="a"

                      href={item.url}
                      target="_blank"
                      sx={(theme) => ({
                        textDecoration: 'none',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        // my: 2,
                        p: 1,
                        fontSize: '0.875rem',
                        fontWeight: '700',
                        ...theme.applyStyles('dark', {
                          bgcolor: '#101010',
                          color: 'grey.300',
                          borderColor: 'grey.800',
                        }),
                      })} >
                      {item.url}
                    </Box>
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default PostTableView