import { Box, Button, FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";
import { useState } from "react";
import { toggleLoader } from "../../../Store/loader/loaderReducer";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";




const FeatureAllow = () => {

    const [values, setValues] = useState({ org_id: '', Feature_type: 1, action_type: 1 })
    const dispatch = useDispatch();
    const admin_info = useSelector((state) => state?.admin_info?.value);
    const FeatureTypeJSON =admin_info&& JSON.parse(admin_info?.feature_allow_info)
    const FeatureType = FeatureTypeJSON&&FeatureTypeJSON



    const handleSubmit = () => {
        let data = {
            org_id: values.org_id,
            feature_type: values.Feature_type,
            action_type: values.action_type,
        };

        let methord = values?.action_type === 0 ? 'post' : 'delete'
        if (methord === 'post') delete data.action_type;

        dispatch(toggleLoader(true));
       if(methord === 'post') {
        axios.post(process.env.REACT_APP_FEATUREALLOW,data,{ withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((res) => {
                res?.data?.response ? toast.warning(res?.data?.response):toast.success("Success");
                dispatch(toggleLoader(false));
            })
            .catch((err) => {
                toast.error(err.message);
                dispatch(toggleLoader(false));
            });
        }
        else {
            axios.delete(process.env.REACT_APP_FEATUREALLOW,  { data:data, withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((res) => {
                toast.success("Success");
                dispatch(toggleLoader(false));
            })
            .catch((err) => {
                toast.error(err.message);
                dispatch(toggleLoader(false));
            })
        }

    }

    return (
        <Box sx={{ padding: "40px", width: '575px' }}>
            <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Org Id:</Box>
                <FormControl size="small" >
                    <OutlinedInput
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        value={values.org_id}
                        onChange={(e) => setValues({ ...values, org_id: Number(e.target.value) })}
                        style={{ height: 35, width: '250px' }}
                        placeholder="Enter the Org Id"
                    />
                </FormControl>
            </Box>
       { FeatureType&& <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Feature Type:</Box>
                <FormControl size="small" >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.Feature_type}
                        onChange={(e) => setValues({ ...values, Feature_type: Number(e.target.value) })}
                        style={{ height: 35, width: '250px' }}
                    >
                        {Object.entries(FeatureType).map(([key, value]) =><MenuItem value={value}> {key}</MenuItem>)}
                    </Select>
                </FormControl>
            </Box>}
            <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Action Type:</Box>
                <FormControl size="small" >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.action_type}
                        onChange={(e) => setValues({ ...values, action_type: Number(e.target.value) })}
                        style={{ height: 35, width: '250px' }}
                    >
                        <MenuItem value={0}>Create</MenuItem>
                        <MenuItem value={1}>Enable All</MenuItem>
                        <MenuItem value={2}>Delete</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box sx={{ paddingTop: '20px', width: '100%', textAlign: 'end' }}>
                <Button variant="contained" onClick={handleSubmit}>Submit</Button>
            </Box>

        </Box>
    )
}
export default FeatureAllow;