import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: null,
};

export const postInfoDetails = createSlice({
    name: 'postInfoDetail',
    initialState,
    reducers: {
        postInfoDetail: (state, action) => {
            if (!action.payload) {
                state.value = null
                return
            }

            const data = [...(state.value?.data || []), ...action.payload.data]
            state.value = {
                ...action.payload, data
            };
        },
    },
});

export const { postInfoDetail } = postInfoDetails.actions;

export default postInfoDetails.reducer;