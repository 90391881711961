import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: null,
};

export const paaQuestionsDetails = createSlice({
    name: 'paaQuestionsDetail',
    initialState,
    reducers: {
        paaQuestionsDetail: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { paaQuestionsDetail } = paaQuestionsDetails.actions;

export default paaQuestionsDetails.reducer;